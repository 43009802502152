<template>
  <div class="custom-history-height">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(55)"
      show-select
      :items="items"
      :headers="headers"
      :items-per-page="itemPerPage"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> {{ $t("no_data_here") }}</template>
      <template v-slot:no-results> {{ $t("no_results_here") }}</template>
      <!--    no-data end-->
      <template
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
          class="zIndex-0"
          style="z-index: -1 !important"
        ></ComponentSelector>
      </template>
      <template #item.action="{ item, header, value }">
        <DataTableActionSelector
          :item="item"
          :actions="actions"
          :handle_function_call="handle_function_call"
        ></DataTableActionSelector>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";
import DataTableActionSelector from "@/own/components/DataTableActionSelector.vue";
// import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import Pagination from "@/own/components/pagination/Pagination.vue";

import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  props: {
    id_field_name: {
      type: String,
      default: "id",
    },
    /** @type {{UPDATE_TABLE_STRUCTURE:string,UPDATE_TABLE_DATA:string,SET_TABLE_SORTBY:string,SET_TABLE_FILTER:string,SET_TABLE_PERPAGE:string,SET_TABLE_PAGE:string,EXPORT_TABLE_DATA:string}} */
    table_state: {
      type: Object,
      required: true,
    },
    /** @type {{getTableFilters:string,getTableState:string,getTableData:string,getTableHeaders:string,getTableProperties:string,getTableExportUrl:string,getTablePermissions:string}} */
    store_names: {
      type: Object,
      required: true,
    },
    // overrideActionsWith: { type: Array, default: () => [] },
  },
  components: {
    DataTableActionSelector,
    // DatatableDetail,
    Pagination,
    ComponentSelector,
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
  }),
  computed: {
    headers: function() {
      /**
       * @type {Array<
       {
       value: string,
       text: string,
       type: string,
       sortable: string,
       exportable: string,
       visible: string,
       mobile_visible: string,
       align: string,
       itemClass: string,
       width: string,
       class: string,
       export_order: integer,
       }>
       }
       */
      let headers = [];
      try {
        if (this.$vuetify.breakpoint.smAndDown) {
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters[this.store_names.getTableHeaders].filter(
                  (col) => col.visible === true && col.mobile_visible === true
                )
              )
            ),
          ];
        } else {
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters[this.store_names.getTableHeaders].filter(
                  (col) => col.visible === true
                )
              )
            ),
          ];
        }
        headers.forEach((header) => {
          let langClass = " text-left ";
          let actionLangClass = " pr-10 ";
          if (this.$vuetify.rtl) {
            langClass = " text-right ";
            actionLangClass = " pl-10 ";
            header.align = "right";
          }
          header.class =
            "text-muted fw-bolder font-size-sm text-uppercase poppins-ls ma-0" +
            langClass;
          header.itemClass = "second-text";
          header.text = this.$t(header.text);
          header.width = undefined;
          if (header.value === "action") {
            header.class =
              "text-muted fw-bolder font-size-sm text-uppercase poppins-ls ma-0" +
              actionLangClass;
          }
        });
      } catch {
        // headers = this.$store.getters[this.store_names.getTableHeaders].filter(
        //   (col) => col.visible === true
        // );
      }

      return headers;
    },
    items: function() {
      /**
       * @type {Array<{}>}
       * @example [{"id":891,"email":"rafi.ogchy@gmail.com","extra_data":null}]
       */
      let items = [];
      try {
        items = this.$store.getters[this.store_names.getTableData].data;
      } catch {
        items = [];
      }
      return items;
    },
    //-------------------activities------------------------
    actions: function() {
      // if (this.overrideActionsWith.length) {
      //   return [...this.overrideActionsWith];
      // }
      return [
        {
          name: "download",
          title: this.$t("download"),
          type: "normal",
          method: "handleDownload",
          isVisible: true,
        },
      ];
    },
    //-------------------pagination----------------------
    getitemPerPage: function() {
      /** @type {number} */
      let itemPerPage = this.$store.getters[this.store_names.getTableState]
        .per_page;
      return itemPerPage;
    },
    getpageNumber: function() {
      /** @type {number} */
      let pageNumber = this.$store.getters[this.store_names.getTableState].page;
      return pageNumber;
    },
    totalItems: function() {
      /** @type {number} */
      let totalItems = this.$store.getters[this.store_names.getTableData].total;
      return totalItems;
    },
    pageCount: function() {
      /** @type {number} */
      let pageCount = this.$store.getters[this.store_names.getTableData]
        .last_page;
      return pageCount;
    },
    itemPerPage: function() {
      /** @type {number} */
      let itemPerPage = this.$store.getters[this.store_names.getTableState]
        .per_page;
      return itemPerPage;
    },
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    async handleDownload(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "/warehouse_management/custom/standard_grn/standard_grn_history/download",
        { id: item.id, warehouse_id: this.selectedWarehouse }
      )
        .then(({ data }) => {
          const link = document.createElement("a");
          link.href = data.file;
          link.target = "_blank";
          link.download = "download.pdf";
          link.click();
          link.remove();
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
        });
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    handle_function_call(function_name, prop) {
      // if (this.overrideActionsWith.length) {
      //   this.$emit(function_name, prop);
      // } else {
      this[function_name](prop);
      // }
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      };
      this.pageLoad(true);
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.selected = [];
        });
      this.$forceUpdate();
      this.pageLoad(false);
    },

    //------------------pagination-------------------
    setitemPerPage(val) {
      this.$store.commit(this.table_state.SET_TABLE_PERPAGE, val);
    },
    setpageNumber(val) {
      this.$store.commit(this.table_state.SET_TABLE_PAGE, val);
    },
  },
};
</script>

<style scoped>
.custom-history-height {
  overflow-y: scroll;
  position: relative;
  min-height: calc(100vh - 10px) !important;
}
</style>
